$(function() {

	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}

	$('.recommends').slick({
		dots: true,
		infinite: true,
		speed: 1000,
		fade: true,
		rtl: dirRtlFlag,
		arrows: false,
		autoplay: false,
		autoplaySpeed: 4000,
		cssEase: 'linear',
		pauseOnHover: false
	});

});
